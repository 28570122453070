import { createContext, useEffect, useState } from "react";
import RootLayout from "./components/Layout";
import { Routes, Route, useLocation } from "react-router-dom";
import { Suspense, lazy } from "react";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MyContext from "./LoaderContext";
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const UpdateVendorPage = lazy(() => import("./pages/UpdateVendorPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const ProjectsPage = lazy(() => import("./pages/ProjectsPage"));
const GalleryPage = lazy(() => import("./pages/GalleryPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUs"));
const CarrerPage = lazy(() => import("./pages/CarrerPage"));
const NewsPage = lazy(() => import("./pages/NewsPage"));
const B2BPage = lazy(() => import("./pages/B2BPage"));
const CandidateDetailsPage = lazy(() => import("./pages/CandidateDetailsPage"));
const NewVendorPage = lazy(() => import("./pages/NewVendorPage"));
const ExistingVendorPage = lazy(() => import("./pages/ExistingVendorPage"));
const PoliciesPage = lazy(() => import("./pages/PoliciesPage"));
const CrsPoliciesPage = lazy(() => import("./pages/CrsPoliciesPage"));

function App() {
  const { pathname } = useLocation();

  // Provider component
  const MyProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);

    return (
      <MyContext.Provider value={{ loader, setLoader }}>
        {children}
      </MyContext.Provider>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname === "/") {
      document.title = "RKC Home";
    } else {
      document.title = `RKC ${pathname.replaceAll("/", "").charAt(0).toUpperCase() +
        pathname.replaceAll("/", "").slice(1)
        }`;
    }
  }, [pathname]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const section = document.getElementById(hash.substring(1));
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 1500);
    }
  }, [pathname,window.location.hash, document]);

  return (
    <MyProvider>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="spinner-border" role="status" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<RootLayout />}>
            <Route index element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="gallery" element={<GalleryPage />} />
            <Route path="contact" element={<ContactUsPage />} />
            <Route path="careers" element={<CarrerPage />} />
            <Route path="news" element={<NewsPage />} />
            <Route path="b2b" element={<B2BPage />} />
            <Route path="career-opening" element={<CandidateDetailsPage />} />
            <Route path="new-vendor" element={<NewVendorPage />} />
            <Route path="existing-vendor" element={<ExistingVendorPage />} />
            <Route path="update-vendor" element={<UpdateVendorPage />} />
            <Route path="policies" element={<PoliciesPage />} />
            <Route path="policies/:policyId" element={<CrsPoliciesPage />} />
            <Route path="policies/terms-of-use" element={<TermsOfUse />} />
            <Route path="policies/privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </MyProvider>
  );
}

export default App;
