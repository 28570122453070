import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/image 1.png";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

const Header = ({ headerData }) => {
  const [activeDropDown, setActiveDropDown] = useState("none");
  const location = useLocation();
  const isActive =
    location.pathname === "/about" ||
    location.pathname === "/policies" ||
    location.hash === "#timeline-sec" ||
    location.hash === "#our-team" ||
    location.hash === "#certificate" ||
    location.hash === "#turnover" ||
    location.hash === "#imported-link";
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = () => {
    setIsCollapsed(!isCollapsed);
    setActiveDropDown("none");
  };

  return (
    <header className="site-header main-header">
      <div className="container-fluid custom-container">
        <div className="header-wrapper">
          <div className="row">
            <div className="col-12">
              <nav className="navbar navbar-expand-lg custom-navbar">
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="logo" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={handleToggle}
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className={`collapse navbar-collapse custom-collapse main-menu ${
                    isCollapsed ? "collapse" : "show"
                  }`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav align-items-center mb-lg-0 ms-auto">
                    <li className="nav-item custom-item">
                      <Link
                        activeClassName="active"
                        className="nav-link custom-link"
                        aria-current="page"
                        to="/"
                        onClick={() => handleLinkClick()}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item custom-item dropdown">
                      <Link
                        className={`nav-link custom-link dropdown-toggle ${
                          isActive ? "active" : null
                        }`}
                        to="/about"
                        id="navbarDropdown"
                        role="button"
                        onClick={() => {
                          if (activeDropDown === "none") {
                            setActiveDropDown("block");
                          } else {
                            setActiveDropDown("none");
                          }
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                      >
                        Company
                      </Link>
                      <ul
                        className="dropdown-menu"
                        style={{
                          display: window.innerWidth <= 985 && activeDropDown,
                        }}
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/about"
                            onClick={handleLinkClick}
                          >
                            About
                          </Link>
                        </li>
                        <li>
                          <HashLink
                            className="dropdown-item"
                            to="/about#timeline-sec"                         
                            onClick={handleLinkClick}
                          >
                            Milestone
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            className="dropdown-item"
                            to="/about#certificate"
                            onClick={handleLinkClick}
                          >
                            Certificate
                          </HashLink>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/about#our-team"
                            onClick={handleLinkClick}
                          >
                            Our Team
                          </Link>
                        </li>
                        <li>
                          <HashLink
                            className="dropdown-item"
                            to="/about#turnover"
                            onClick={handleLinkClick}
                          >
                            Financial
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            to="/about#imported-link"
                            className="dropdown-item"
                            onClick={handleLinkClick}
                          >
                            Policies
                          </HashLink>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item custom-item">
                      <NavLink
                        activeClassName="active"
                        to="/projects"
                        className="nav-link custom-link"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        Projects
                      </NavLink>
                    </li>
                    <li className="nav-item custom-item">
                      <NavLink
                        activeClassName="active"
                        to="/gallery"
                        className="nav-link custom-link"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        Gallery
                      </NavLink>
                    </li>
                    <li className="nav-item custom-item">
                      <NavLink
                        activeClassName="active"
                        to="/careers"
                        className="nav-link custom-link"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li className="nav-item custom-item">
                      <NavLink
                        activeClassName="active"
                        to="/b2b"
                        className="nav-link custom-link"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        B2B
                      </NavLink>
                    </li>
                    <li className="nav-item custom-item">
                      <NavLink
                        activeClassName="active"
                        to="/news"
                        className="nav-link custom-link"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        News
                      </NavLink>
                    </li>
                    <li className="nav-item contact-top-btn">
                      <Link
                        to="/contact"
                        className="nav-link ctc-top-text"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        Contact us
                      </Link>
                      <Link
                        to="/contact"
                        className="nav-link ctc-top-number"
                        aria-disabled="true"
                        onClick={handleLinkClick}
                      >
                        +91-{headerData && headerData.mobile}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
